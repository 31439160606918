export const CHAIN_ID = 1

export const UNLIMITED = '11579208923731619542357098500868790785326998466564056403945758400791312963993'

export const RPC_URL_MAP = {
  [1]: 'https://ethereum-rpc.publicnode.com',
  [5]: 'https://rpc.ankr.com/eth_goerli',
  [56]: 'https://bsc-dataseed1.defibit.io',
  [97]: 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
}

export const EXPLORER_URLS = {
  [1]: 'https://etherscan.io',
  [5]: 'https://goerli.etherscan.io',
  [56]: 'https://bsc-dataseed1.defibit.io',
  [97]: 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
}

export const NUMERALS = {
  [0]: 'X',
  [1]: 'I',
  [2]: 'II',
  [3]: 'III',
  [4]: 'IV',
  [5]: 'V',
  [6]: 'VI',
}

export const ENV_LAND_ADDRESS = {
  [1]: '0x9C5bf30a3FAd6469c4695a5e74632012bd406A95',
  [5]: '0x3dA753D3820aa9fD7D5D3b23307F0a06444BcE3b',
}

type NetworkMap = {
  [key: number]: any
}

export const NETWORK_MAP: NetworkMap = {
  [1]: {
    id: '1',
    name: 'Ethereum Mainnet',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://mainnet.infura.io/v3'],
  },
  [5]: {
    id: '5',
    name: 'Goerli',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    rpcUrls: ['https://rpc.ankr.com/eth_goerli'],
  },
  [56]: {
    id: '56',
    name: 'BNB Smart Chain',
    nativeCurrency: { name: 'BSC', symbol: 'BSC', decimals: 18 },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
  },
  [97]: {
    id: '97',
    name: 'BSC Testnet',
    nativeCurrency: { name: 'BNB', symbol: 'tBNB', decimals: 18 },
    rpcUrls: ['https://endpoints.omniatech.io/v1/bsc/testnet/public'],
  },
}
