import { goerli, bsc, bscTestnet } from 'wagmi/chains'
import { Chain } from 'wagmi'

export enum ENV_TYPES {
  DEVELOPMENT = 0,
  PRODUCTION = 1,
}

export const mainnet = {
  id: 1,
  network: 'homestead',
  name: 'Ethereum',
  nativeCurrency: {
    name: 'Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    alchemy: {
      http: ['https://eth-mainnet.g.alchemy.com/v2'],
      webSocket: ['wss://eth-mainnet.g.alchemy.com/v2'],
    },
    infura: {
      http: ['https://mainnet.infura.io/v3'],
      webSocket: ['wss://mainnet.infura.io/ws/v3'],
    },
    default: {
      http: ['https://ethereum-rpc.publicnode.com'],
    },
    public: {
      http: ['https://ethereum-rpc.publicnode.com'],
    },
  },
  blockExplorers: {
    etherscan: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
    default: {
      name: 'Etherscan',
      url: 'https://etherscan.io',
    },
  },
  contracts: {
    ensRegistry: {
      address: '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e',
    },
    ensUniversalResolver: {
      address: '0xc0497E381f536Be9ce14B0dD3817cBcAe57d2F62',
      blockCreated: 16966585,
    },
    multicall3: {
      address: '0xca11bde05977b3631167028862be2a173976ca11',
      blockCreated: 14353601,
    },
  },
} as Chain

const ENV_ASSETS_BASE_URI = {
  [ENV_TYPES.DEVELOPMENT]: 'https://dev-assets.deepspace.game',
  [ENV_TYPES.PRODUCTION]: 'https://assets.deepspace.game',
}

const ENV_BACKEND_URI = {
  // [ENV_TYPES.DEVELOPMENT]: "https://deepspace-web-dev.azurewebsites.net",
  [ENV_TYPES.DEVELOPMENT]: 'https://dev-api.deepspace.game',
  [ENV_TYPES.PRODUCTION]: 'https://api.deepspace.game',
}

const ENV_CHAIN_ID_ETH = {
  [ENV_TYPES.DEVELOPMENT]: 5,
  [ENV_TYPES.PRODUCTION]: 1,
}

const ENV_CHAINS = {
  [ENV_TYPES.DEVELOPMENT]: [goerli, bscTestnet],
  [ENV_TYPES.PRODUCTION]: [mainnet, bsc],
}

const ENV_CHAIN_ID_BSC = {
  [ENV_TYPES.DEVELOPMENT]: 97,
  [ENV_TYPES.PRODUCTION]: 56,
}

const ENV_CONTRACT_ADDRESS_TOKEN_ETH = {
  [ENV_TYPES.DEVELOPMENT]: '0xf9ffee9A77b29F703D3c432111fD9eCee6806376',
  [ENV_TYPES.PRODUCTION]: '0xB7B1570e26315BAAD369b8EA0a943b7F140DB9eB',
}

const ENV_CONTRACT_ADDRESS_TOKEN_BSC = {
  [ENV_TYPES.DEVELOPMENT]: '0xdd62D3F024DcBEcd8F7309183f59DC66231CFd2d',
  [ENV_TYPES.PRODUCTION]: '0xf275e1AC303a4C9D987a2c48b8E555A77FeC3F1C',
}

const ENV_CONTRACT_ADDRESS_BRIDGE_ETH = {
  [ENV_TYPES.DEVELOPMENT]: '0x3711426d281C65f1542420c798df7cD9743341A0',
  [ENV_TYPES.PRODUCTION]: '0x81781C4705eaf51D2D2DBeb5693690be6DdCBA38',
}

const ENV_CONTRACT_ADDRESS_BRIDGE_BSC = {
  [ENV_TYPES.DEVELOPMENT]: '0x4E03Bbe79680C1BB7cb7f8c0Da0D3493DAB1Abd3',
  [ENV_TYPES.PRODUCTION]: '0xfD9ECA072F7c48cd741732a059738cB101f0aA3b',
}

const ENV_CONTRACT_ADDRESS_RESOURCES_BSC = {
  [ENV_TYPES.DEVELOPMENT]: '0xF81b73C58c23DeAc1432E225e5439E74EB91274d',
  [ENV_TYPES.PRODUCTION]: '0x55bE5599a5CFF3A85AE0Cf2fe24AB018EC97d34D',
}

export function getEnvironment(): ENV_TYPES {
  switch (process.env.NEXT_PUBLIC_ENVIRONMENT) {
    case 'production':
      return ENV_TYPES.PRODUCTION
    default:
      return ENV_TYPES.DEVELOPMENT
  }
}

export function getConfig(envKey: string, defaultValue: any) {
  const varName = 'NEXT_PUBLIC_' + envKey
  if (process.env[varName]) {
    return process.env[varName]
  }
  return defaultValue
}

class Config {
  public static backendUrl = () => ENV_BACKEND_URI[getEnvironment()]
  public static assetsBaseUri = () => ENV_ASSETS_BASE_URI[getEnvironment()]
  public static contractAddressTokenEth = () => ENV_CONTRACT_ADDRESS_TOKEN_ETH[getEnvironment()]
  public static contractAddressTokenBsc = () => ENV_CONTRACT_ADDRESS_TOKEN_BSC[getEnvironment()]
  public static contractAddressBridgeEth = () => ENV_CONTRACT_ADDRESS_BRIDGE_ETH[getEnvironment()]
  public static contractAddressBridgeBsc = () => ENV_CONTRACT_ADDRESS_BRIDGE_BSC[getEnvironment()]
  public static contractAddressResourcesBsc = () => ENV_CONTRACT_ADDRESS_RESOURCES_BSC[getEnvironment()]
  public static chainIdEth = () => ENV_CHAIN_ID_ETH[getEnvironment()]
  public static chainIdBsc = () => ENV_CHAIN_ID_BSC[getEnvironment()]
  public static chains = () => ENV_CHAINS[getEnvironment()]
}

export { Config }
